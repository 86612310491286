<template>
  <div>
    <div class="out">
      <div class="header">
        <div class="logo Title">
          <div class="Chinese">
            <img src="../assets/img/return_index.png" class="return1" alt @click="$router.back()" />
            {{$t('main.holdertran')}}
          </div>
        </div>
        <div class="right-icon">
          <img src="../assets/img/logo-icon.png" alt />
          <div class="cc-wc">{{$t('main.titleCenter')}}</div>
        </div>
      </div>
      <div class="content">
        <div class="zb-edit">
          <div class="zb-pic zb-edit-item">
            <div class="zb-edit-item-top" style="margin-bottom:0">
              <div class="title-ltfe nomore">
                <span>文创品名称：</span>
                <span style="color:#0056d7">{{name}}</span>
              </div>
            </div>
            <div class="zb-edit-item-top" style="margin-bottom:0.1rem;">
              <div class="title-ltfe" style="font-size:0.15rem;">
                <span>文创品编码：</span>
                <span style="color:#fe0000">{{serial_number}}</span>
              </div>
            </div>
            <div class="step step1">
              <img class="top-text" src="../assets/img/step-icon1.png" alt />
              <div class="mark-flex">
                <div class="mark-out">
                  <div class="mark-in"></div>
                </div>
                {{$t('main.arstep')}}
              </div>
            </div>
            <div class="line-x line-x1"></div>
            <div class="step step2">
              <img class="top-text" src="../assets/img/step-icon2.png" alt />
              <div class="mark-flex">
                <div class="mark-out">
                  <div class="mark-in"></div>
                </div>
                {{$t('main.allstep2')}}
              </div>

              <div class="qr-code" id="qrcode"></div>
              <div class="qr-tips">{{$t('main.qrcode30')}}</div>
              <div class="tip_btn" @click="getData">
                <div v-show="isReturn">
                  <img src="../assets/img/tips.png" alt />
                </div>
                <div>{{$t('main.cerinfo')}}</div>
              </div>
            </div>
            <div class="line-x line-x2"></div>
            <div class="step step3">
              <img class="top-text" src="../assets/img/step-icon3_g.png" alt />
              <div class="mark-flex flex_start">
                <div class="mark-out">
                  <div class="mark-in"></div>
                </div>
                <div
                  class="mark-text"
                  style="color:#4ab006"
                >{{$t('main.cyzstep3')}}{{$route.query.transfer_fee}}</div>
              </div>
            </div>
            <div class="line-x line-x3"></div>
            <div class="step step3">
              <img class="top-text" src="../assets/img/step-icon4.png" alt />
              <div class="mark-flex flex_start">
                <div class="mark-out">
                  <div class="mark-in"></div>
                </div>
                <div class="mark-text">{{$t('main.allstep4')}}</div>
              </div>
            </div>
            <div class="sq-tips-text">{{$t('main.noteRZ')}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="pop pop-cy-rz" v-show="info" @click="closeQr">
      <div class="pop_zhuanyi" @click.stop>
        <div class="header header_noshadow">
          <div class="logo Title">
            <div class="English">CERTIFICATION TRANSFER</div>
            <div class="Chinese">{{$t('main.holdertran')}}</div>
          </div>
        </div>
        <div class="pop_body">
          <div class="wx_info">
            <img :src="pop.avatar" alt />
            <div class="wx_info_name">
              <div class>
                {{$t('main.wxnickname')}}：
                <span style="color:#4ab006">{{pop.username}}</span>
              </div>
              <div>{{$t('main.syatca')}}</div>
            </div>
          </div>
          <div class="list_item">认证文创品信息：</div>
          <div class="list_item">
            <span>文创品名称：</span>
            <span style="color:#0056d7">{{name}}</span>
          </div>
          <div class="list_item">
            <span>长城文创品编码：</span>
            <span style="color:#fe0000">{{serial_number}}</span>
          </div>
          <!-- <div class="list_item">
            <span>{{$t('main.lastva')}}</span>
            <span>{{format_with_regex(price)}}</span>
            <div
              :class="['old_price',price - old_price > 0 ? 'more': price - old_price < 0?'less':'' ]"
            >{{format_with_regex(Math.abs(price - old_price))}}</div>
          </div> -->
          <div class="list_item">
            <span>{{$t('main.cerdisplay')}}</span>
            <span>{{pop.name == pop.username?$t('main.wxnickname'):pop.name}}</span>
          </div>
          <div class="list_item_block">
            {{$t('main.msypc')}}
            <div style="color:#4ab006;margin-top: 0.15rem;">{{pop.username}}</div>
          </div>
          <div class="list_item">{{$t('main.acts')}}</div>
        </div>
        <div class="button_list">
          <div @click="verify(2)">{{$t('main.cancel')}}</div>
          <div @click="verify(1)">{{$t('main.determine')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      info: false,
      isInfo: false,
      qrcode: "",
      pop: {
        avatar: "",
        username: "",
        name: ""
      },
      name: "",
      serial_number: "",
      isReturn: false,
      ar_id: "",
      price: 0,
      old_price: 0
    };
  },
  mounted() {
    this.name = this.$route.query.name;
    this.serial_number = this.$route.query.serial_number;
    this.init();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async init() {
      await this.axios
        .get(
          `/collectionder/invite?goods_derivative_id=${this.$route.query.goods_id}&id=${this.$route.query.id}`
        )
        .then(res => {
          console.log(res);
          this.qrcode = res.data.data.qrCode;
          this.isInfo = !!res.data.data.applyRecord;
          if (res.data.data.applyRecord) {
            this.pop.username = res.data.data.applyRecord.user.username;
            this.pop.avatar = res.data.data.applyRecord.user.avatar;
            this.pop.name = res.data.data.applyRecord.name;
            this.isReturn = true;
            this.ar_id = res.data.data.applyRecord.id;
            this.price = parseFloat(res.data.data.applyRecord.price);
            this.old_price = parseFloat(res.data.data.applyRecord.parent.price);
          } else {
            this.timer = setInterval(() => {
              this.axios
                .get(
                  `/collectionder/invite?goods_derivative_id=${this.$route.query.goods_id}&id=${this.$route.query.id}`
                )
                .then(res => {
                  if (res.data.data.applyRecord) {
                    this.pop.username = res.data.data.applyRecord.user.username;
                    this.pop.avatar = res.data.data.applyRecord.user.avatar;
                    this.pop.name = res.data.data.applyRecord.name;
                    this.isReturn = true;
                    this.ar_id = res.data.data.applyRecord.id;
                    this.price = parseFloat(res.data.data.applyRecord.price);
                    this.old_price = parseFloat(
                      res.data.data.applyRecord.parent.price
                    );
                    clearInterval(this.timer);
                  }
                });
            }, 5000);
          }
        });

      if(!this.qrcode){
        return
      }
      let url = location.protocol + "//" + location.host;
      new QRCode(document.getElementById("qrcode"), {
        text:
          url +
          "/api/auth/login?url=" +
          encodeURIComponent(
            url +
              "/zy_cul?id="+
              this.$route.query.goods_id
              +'&qrCode='+this.qrcode
          ), //要生成二维码的网址
        width: 256, //图像宽度
        height: 256, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        margin: 0, //外边距
        correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
      });
    },
    closeQr() {
      this.info = false;
    },
    getData() {
      if (this.isReturn) {
        this.info = true;
        return;
      }
      this.axios
        .get(
          `/collectionder/invite?goods_derivative_id=${this.$route.query.goods_id}&id=${this.$route.query.id}`
        )
        .then(res => {
          if (res.data.data.applyRecord) {
            this.pop.username = res.data.data.applyRecord.user.username;
            this.pop.avatar = res.data.data.applyRecord.user.avatar;
            this.pop.name = res.data.data.applyRecord.name;
            this.info = true;
            this.isReturn = true;
            this.ar_id = res.data.data.applyRecord.id;
            this.price = parseFloat(res.data.data.applyRecord.price);
            this.old_price = parseFloat(res.data.data.applyRecord.parent.price);
          } else {
            weui.alert(this.$t("main.holderNsu"), {
              buttons: [{ label: this.$t("main.confIrm") }]
            });
          }
        });
    },
    verify(status) {
      this.axios
        .post("/collectionder/verify", { id: this.ar_id, status: status })
        .then(res => {
          if (res.data.code == 0) {
            if (status == 1) {
              this.info = false;
              weui.alert(this.$t("main.cersucc"), {
                buttons: [
                  {
                    label: this.$t("main.confIrm"),
                    onClick: () => {
                      this.$router.push("/zbbox");
                    }
                  }
                ]
              });
            } else {
              this.info = false;
              weui.alert(this.$t("main.authcancel"), {
                buttons: [
                  {
                    label: this.$t("main.confIrm"),
                    onClick: () => {
                      this.$router.push("/zbbox");
                    }
                  }
                ]
              });
            }
          } else {
            weui.alert(res.data.msg, {
              buttons: [{ label: this.$t("main.confIrm") }]
            });
          }
        });
    },
    format_with_regex(number) {
      if (number == 0) {
        return "";
      }
      return !(number + "").includes(".")
        ? // 就是说1-3位后面一定要匹配3位
          (number + "").replace(/\d{1,3}(?=(\d{3})+$)/g, match => {
            return match + ",";
          })
        : (number + "").replace(/\d{1,3}(?=(\d{3})+(\.))/g, match => {
            return match + ",";
          });
    }
  }
};
</script>
  
  <style scoped>
.out {
  padding: 0rem 0.15rem;
  min-height: 100vh;
  box-sizing: border-box;
  background: url(../assets/img/in-bg.jpg) no-repeat center;
  background-size: cover;
}

.header {
  display: flex;
  justify-content: space-between;
  /* line-height: 0; */
  margin-bottom: 0rem;
  margin-bottom: 0rem;
  margin: 0 -0.15rem;
  padding: 0 0.2rem;
  align-items: center;
  margin-bottom: 0;
  box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
  padding-bottom: 0.09rem;
  padding-top: 0.05rem;
  background: #ffffff;
}
.header_noshadow {
  box-shadow: none;
}

.Title {
  font-size: 0.16rem;
  position: relative;
}

.English {
  color: #d6eedc;
  position: absolute;
  top: 0.1rem;
  left: 0.15rem;
  white-space: nowrap;
}

.Chinese {
  color: #1b6a21;
  /* top: -0.2rem; */
  left: 0;
  position: relative;
  text-align: left;
  font-size: 0.18rem;
}

.cc-wc {
  font-size: 0.1rem;
  color: #e9630a;
}

.right-icon {
  /* width: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
  width: 0.65rem;
}

.zb-edit {
  font-size: 0.16rem;
  padding-bottom: 0.23rem;
  padding: 0.15rem;
  background: #fff;
  padding-bottom: 0.25rem;
}

.zb-edit-item {
  margin: 0;
  padding: 0;
  background: #ffffff;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
  /* margin-bottom: 0.17rem; */
  padding-top: 0;
  padding-bottom: 0.4rem;
  position: relative;
}

.zb-edit-item-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9e9e9e;
  margin-bottom: 0.21rem;
}

.step {
  color: #343434;
  font-size: 0.15rem;
  margin-bottom: 0.22rem;
  display: flex;
  flex-direction: column;
}
.step3 {
  margin-bottom: 0.1rem;
}
.body {
  padding: 0.15rem;
}
.list_item {
  color: #343434;
  font-size: 0.15rem;
  margin-bottom: 0.14rem;
}
.qr-code {
  margin-top: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 256px;
}
.qr-tips {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #9e9e9e;
  font-size: 0.14rem;
  margin-bottom: 0.14rem;
}
.tip_btn {
  background: #3662ec;
  border-radius: 6px;
  margin: 0 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 0.14rem;
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
}
.tip_btn img {
  width: 0.2rem;
  height: 0.2rem;
  display: block;
  margin-right: 0.1rem;
}
.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  /* display: none; */
}
.pop_body {
  padding: 0.15rem;
  box-sizing: border-box;
}
.pop_zhuanyi {
  width: calc(100% - 0.3rem);

  background: #ffffff;
  padding: 0.12rem 0.15rem 0.15rem 0.15rem;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wx_info {
  display: flex;
  margin-bottom: 0.24rem;
}
.wx_info img {
  width: 1.02rem;
  height: 1.02rem;
  border-radius: 0.05rem;
  display: block;
}
.wx_info_name {
  height: 1.02rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.18rem;
  color: #343434;
  font-size: 0.15rem;
}
.button_list {
  display: flex;
  justify-content: space-between;
}
.button_list div {
  cursor: pointer;
  padding: 0.1rem 0.53rem;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
}
.button_list div:nth-child(1) {
  background: #66676a;
}
.button_list div:nth-child(2) {
  background: #3662ec;
}
.title-ltfe {
  font-size: 0.18rem;
  color: #343434;
}

.title-right {
  font-size: 0.12rem;
}

.zb-edit-item-bottom input {
  height: 0.35rem;
  width: 100%;
  outline: none;
  margin-bottom: 0.15rem;
  border-radius: 6px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  border: none;
  padding-left: 0.1rem;
  box-sizing: border-box;
}

.sq-box {
  display: flex;
  align-items: center;
  margin-bottom: 0.26rem;
  color: #9e9e9e;
  font-size: 0.14rem;
  justify-content: space-between;
}

.sq-box .sq-btn {
  width: 1.16rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  border-radius: 0.1rem;
  background: #3662ec;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.sq-tips-text {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  color: #9e9e9e;
  font-size: 0.14rem;
  margin-bottom: 0;
}

.mark-text {
  width: 2.9rem;
}
.agree-box {
  display: flex;
  align-items: center;
}

.agree-box span {
  font-size: 0.14rem;
  font-weight: 500;
  letter-spacing: 0px;
  color: #9e9e9e;
  margin-left: 0.1rem;
}

.submint-info {
  width: 100%;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background: #2d71d7;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
  /* margin-top:0.23rem; */
  margin-bottom: 0;
}
.top-text {
  width: 0.285rem;
  margin-bottom: -0.08rem;
  margin-left: 0.25rem;
}
.mark-flex {
  display: flex;
  align-items: center;
}
.mark-out {
  width: 0.14rem;
  height: 0.14rem;
  border: 1px #2d71d7 solid;
  border-radius: 50%;
  padding: 0.02rem;
  box-sizing: border-box;
  margin-right: 0.05rem;
}
.mark-in {
  width: 100%;
  height: 100%;
  background: #2d71d7;
  border-radius: 50%;
}
.line-x {
  border-left: 2px dashed #2d71d7;

  position: absolute;
  left: 0.06rem;
}
.line-x.line-x1 {
  height: 0.38rem;
  top: 1.27rem;
}
.line-x.line-x2 {
  height: calc(281px + 1.15rem);
  top: 1.87rem;
}
.line-x.line-x3 {
  height: 0.3rem;
  top: calc(2.55rem + 350px);
}
.flex_start {
  align-items: flex-start;
}
.flex_start .mark-out {
  margin-top: 0.06rem;
}
.old_price {
  display: inline-block;
  margin-left: 1rem;
  position: relative;
  color: #ff4200;
}
.more::before {
  content: "";
  position: absolute;
  width: 0.16rem;
  height: 0.13rem;
  background: url(../assets/img/raise.png) no-repeat;
  background-size: 100%;
  top: 0.05rem;
  left: -0.2rem;
}
.less::before {
  content: "";
  position: absolute;
  width: 0.16rem;
  height: 0.13rem;
  background: url(../assets/img/downdd.png) no-repeat;
  background-size: 100%;
  top: 0.05rem;
  left: -0.2rem;
}

.list_item_block {
  width: 100%;
  box-sizing: border-box;
  padding: 0.215rem 0.175rem;
  text-align: center;
  background: #f9fbff;
  box-shadow: 0 0 0 0.01rem #2d71d7;
  border-radius: 0.08rem;
  margin-bottom: 0.14rem;
}
.nomore {
  overflow: hidden;

  text-overflow: ellipsis;

  -webkit-box-orient: vertical;

  display: -webkit-box;

  -webkit-line-clamp: 2;

  -webkit-box-flex: 2;
  height: 0.56rem;
}
</style>